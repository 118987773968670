<template>
	<div style="width: 100%;height: 100%;">
		<div style="width:98%;margin-left: 1%;margin-top:1.5%;height:95vh;background-color: white;box-shadow:#e0e0e0 5px 5px 10px;overflow-y: auto;">
			<div style="width:95%;padding:2.5%;padding-top: 1%;display: flex;flex-direction: column;flex:1">
				<p style="font-size: 20px;margin-bottom:0.5%;font-weight: 700">欢迎光临，{{username}}</p>
				<p style="font-size: 16px;margin-bottom:0.5%">现在是 {{nowDate}}</p>
				<div style="width:100%;height:0.5px;margin-top:10px;margin-bottom:10px;;border-bottom: 0.5px solid #ebeef5"></div>
				<div style="font-size: 20px;color:black;font-weight: 700;margin-bottom: 15px">数据统计</div>
				<div style="width: 100%;display: flex;flex-direction: row;margin-bottom: 15px;">
					<div style="width:23%;margin-left:1%;margin-right:1%;height:100px;display: flex;flex-direction: column;align-items: center;justify-content: center;
					box-shadow: 5px 5px 10px #e0e0e0;
					">
						<div style="font-size: 30px;color:#3c9cff">{{data.todayOrdersNumber}}</div>
						<div style="font-size: 20px;">今日订单总数</div>
					</div>
					<div style="width:23%;margin-left:1%;margin-right:1%;height:100px;display: flex;flex-direction: column;align-items: center;justify-content: center;
					box-shadow: 5px 5px 10px #e0e0e0;
					">
						<div style="font-size: 30px;color:#00aa00">{{data.todaySalesRevenueNumber}}</div>
						<div style="font-size: 20px;">今日销售总额</div>
					</div>
					<div style="width:23%;margin-left:1%;margin-right:1%;height:100px;display: flex;flex-direction: column;align-items: center;justify-content: center;
					box-shadow: 5px 5px 10px #e0e0e0;
					">
						<div style="font-size: 30px;color:#ffaa00">{{data.yesterdaySalesRevenueNumber}}</div>
						<div style="font-size: 20px;">昨日销售总额</div>
					</div>
					<div style="width:23%;margin-left:1%;margin-right:1%;height:100px;display: flex;flex-direction: column;align-items: center;justify-content: center;
					box-shadow: 5px 5px 10px #e0e0e0;
					">
						<div style="font-size: 30px;color:#aa00ff">{{data.sevenDaysSalesRevenueNumber}}</div>
						<div style="font-size: 20px;">近7天销售总额</div>
					</div>
				
				</div>
				<div style="width:100%;height:0.5px;margin-top:10px;margin-bottom:10px;;border-bottom: 0.5px solid #ebeef5"></div>
				<div style="font-size: 20px;font-weight:700;width: 100%;display: flex;align-items: center;margin-bottom: 2vh;margin-top: 1vh;">
					待处理事务
				</div>
				<div style="font-size: 18px;font-weight:500;color:#474747;width: 100%;display: flex;align-items: center;margin-bottom: 1vh;">
					待审核订单{{ data.pendingReviewOrders }}
				</div>
				<div style="font-size: 18px;font-weight:500;color:#474747;width: 100%;display: flex;align-items: center;margin-bottom: 1vh;">
					待审核门店{{ data.pendingReviewShop }}
				</div>
				<div style="font-size: 18px;font-weight:500;color:#474747;width: 100%;display: flex;align-items: center;margin-bottom: 2vh;">
					待审核匠人{{ data.pendingReviewCraftsMan }}
				</div>
				<div style="width:100%;height:0.5px;margin-top:10px;margin-bottom:10px;;border-bottom: 0.5px solid #ebeef5"></div>
				<div style="font-size: 20px;font-weight:700;width: 100%;display: flex;align-items: center;margin-bottom: 2vh;">
					运营快捷入口
				</div>
				<div style="font-size: 20px;font-weight:700;width: 100%;display: flex;margin-bottom: 2vh;flex-direction: row;">
					<div style="width:70px;margin-right:60px;display: flex;flex-direction: column;align-items: center;justify-content: center;"
					@click="page('theme')"
					>
						<i class="el-icon-tickets" style="font-size: 45px;"></i>
						<P style="font-weight: 500;font-size: 16px;">文章管理</P>
					</div>
					<div style="width:70px;margin-right:60px;display: flex;flex-direction: column;align-items: center;justify-content: center;"
					@click="page('jiangrenlist')"
					>
						<i class="el-icon-s-custom" style="font-size: 45px;"></i>
						<P style="font-weight: 500;font-size: 16px;">匠人管理</P>
					</div>
					<div style="width:70px;margin-right:60px;display: flex;flex-direction: column;align-items: center;justify-content: center;"
					@click="page('pinleilist')"
					>
						<i class="el-icon-menu" style="font-size: 45px;"></i>
						<P style="font-weight: 500;font-size: 16px;">品类管理</P>
					</div>
					<div style="width:70px;margin-right:60px;display: flex;flex-direction: column;align-items: center;justify-content: center;"
					@click="page('jifen')"
					>
						<i class="el-icon-present" style="font-size: 45px;"></i>
						<P style="font-weight: 500;font-size: 16px;">礼品管理</P>
					</div>
				</div>
				<div style="width:100%;height:0.5px;margin-top:10px;margin-bottom:10px;;border-bottom: 0.5px solid #ebeef5"></div>
				<div style="width:100%;display:flex;flex-direction: row;">
						<div style="width: 50%;">
							<div style="font-size: 20px;font-weight:700;width: 100%;display: flex;align-items: center;margin-bottom: 2vh;">
								订单总览
							</div>
							<div style="width:100%;display:flex;flex-direction: row;">
								<div style="width:20%;margin-left:1%;margin-right:2%;height:100px;display: flex;flex-direction: column;align-items: center;justify-content: center;
									box-shadow: 5px 5px 10px #e0e0e0;
								">
									<div style="font-size: 30px;color:#3c9cff">{{data.successOrders}}</div>
									<div style="font-size: 20px;">已完成</div>
								</div>
								<div style="width:20%;margin-left:1%;margin-right:2%;height:100px;display: flex;flex-direction: column;align-items: center;justify-content: center;
									box-shadow: 5px 5px 10px #e0e0e0;
								">
									<div style="font-size: 30px;color:#3c9cff">{{data.waitOrders}}</div>
									<div style="font-size: 20px;">待审核</div>
								</div>
								<div style="width:20%;margin-left:1%;margin-right:2%;height:100px;display: flex;flex-direction: column;align-items: center;justify-content: center;
									box-shadow: 5px 5px 10px #e0e0e0;
								">
									<div style="font-size: 30px;color:#3c9cff">{{data.failureOrders}}</div>
									<div style="font-size: 20px;">审核失败</div>
								</div>
								<div style="width:20%;margin-left:1%;margin-right:2%;height:100px;display: flex;flex-direction: column;align-items: center;justify-content: center;
									box-shadow: 5px 5px 10px #e0e0e0;
								">
									<div style="font-size: 30px;color:#3c9cff">{{data.totalOrders}}</div>
									<div style="font-size: 20px;">全部订单</div>
								</div>
							</div>
						</div>
						<div style="width: 50%;">
							<div style="font-size: 20px;font-weight:700;width: 100%;display: flex;align-items: center;margin-bottom: 2vh;">
								匠人总览
							</div>
							<div style="width:100%;display:flex;flex-direction: row;">
								<div style="width:20%;margin-left:1%;margin-right:2%;height:100px;display: flex;flex-direction: column;align-items: center;justify-content: center;
									box-shadow: 5px 5px 10px #e0e0e0;
								">
									<div style="font-size: 30px;color:#3c9cff">{{data.todayInsertNumber}}</div>
									<div style="font-size: 20px;">今日新增</div>
								</div>
								<div style="width:20%;margin-left:1%;margin-right:2%;height:100px;display: flex;flex-direction: column;align-items: center;justify-content: center;
									box-shadow: 5px 5px 10px #e0e0e0;
								">
									<div style="font-size: 30px;color:#3c9cff">{{data.yesterdayInsertNumber}}</div>
									<div style="font-size: 20px;">昨日新增</div>
								</div>
								<div style="width:20%;margin-left:1%;margin-right:2%;height:100px;display: flex;flex-direction: column;align-items: center;justify-content: center;
									box-shadow: 5px 5px 10px #e0e0e0;
								">
									<div style="font-size: 30px;color:#3c9cff">{{data.currentMonthInsertNumber}}</div>
									<div style="font-size: 20px;">本月新增</div>
								</div>
								<div style="width:20%;margin-left:1%;margin-right:2%;height:100px;display: flex;flex-direction: column;align-items: center;justify-content: center;
									box-shadow: 5px 5px 10px #e0e0e0;
								">
									<div style="font-size: 30px;color:#3c9cff">{{data.previousMonthInsertNumber}}</div>
									<div style="font-size: 20px;">匠人总数</div>
								</div>
							</div>
						</div>
						
					</div>
					<div style="width:100%;height:0.5px;margin-top:10px;margin-bottom:10px;;border-bottom: 0.5px solid #ebeef5"></div>
					
			</div>
			
		</div>
	</div>
</template>

<script>
import * as echarts from 'echarts';
import {post} from "../axios/request.js"
export default {
  name:"indexpage",
  data() {
    return {
		data:{},
		tongji:{
			usernum:0
		},
		nowDate:"",
		username:"",
		option : {
		  title: {
		    text: '',
		    left: 'center'
		  },
		  tooltip: {
		    trigger: 'item'
		  },
		  legend: {
		    orient: 'vertical',
		    left: 'left'
		  },
		  series: [
		    {
		      name: '分类列表',
		      type: 'pie',
		      radius: '50%',
		      data: [
		      ],
		      emphasis: {
		        itemStyle: {
		          shadowBlur: 10,
		          shadowOffsetX: 0,
		          shadowColor: 'rgba(0, 0, 0, 0.5)'
		        }
		      }
		    }
		  ],
		}
    }
  },
  mounted() {
	//console.log(JSON.parse(localStorage.getItem("userinfo")));
	this.username=JSON.parse(localStorage.getItem("userinfo")).nickname
	
	post("/total/statisticTotalIndex",{}).then((res)=>{
		this.data=res.data.list;
	})
	setInterval(this.formatDate, 500);
  },
  methods: {
	page(e){
		this.$emit("changepage",e); 
	},
	formatDate() {
	     let date = new Date();
	     let year = date.getFullYear(); // 年
	     let month = date.getMonth() + 1; // 月
	     let day = date.getDate(); // 日
	     let week = date.getDay(); // 星期
	     let weekArr = [ "星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六" ];
	     let hour = date.getHours(); // 时
	     hour = hour < 10 ? "0" + hour : hour; // 如果只有一位，则前面补零
	     let minute = date.getMinutes(); // 分
	     minute = minute < 10 ? "0" + minute : minute; // 如果只有一位，则前面补零
	     let second = date.getSeconds(); // 秒
	     second = second < 10 ? "0" + second : second; // 如果只有一位，则前面补零
	     this.nowDate = `${year}年${month}月${day}日 ${hour}:${minute}:${second} `+weekArr[week];
	},
  }
}
</script>
<style scoped>

</style>