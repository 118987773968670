<template>
	<div style="width: 100%;height: 100%;">
		<div style="width:98%;margin-left: 1%;margin-top:1.5%;height:95%;background-color: white;box-shadow:#e0e0e0 5px 5px 10px;">
			<div style="width:95%;height:96%;padding:2%;display: flex;flex-direction: column;">
			
				<div style="width:100%;display: flex;flex-direction: row;align-items: center;height:5vh;margin-bottom: 3vh;"
				
				>	
                    <el-tag type="Dark"  v-if="shenhe==1" effect="dark">{{ '全部匠人' }}</el-tag>
                    <el-tag type="Dark" @click="shenhe=1,getmore()"  v-if="shenhe!=1" effect="plain">{{ '全部匠人' }}</el-tag>
                    <el-tag type="Dark"  v-if="shenhe==0" style="margin-left:20px" effect="dark">{{ '待审核' }}</el-tag>
                    <el-tag type="Dark" @click="shenhe=0,getmore()" v-if="shenhe!=0" style="margin-left:20px" effect="plain">{{ '待审核' }}</el-tag>
					<el-input style="width: 20vw;margin-left:20px " v-model="shopid" placeholder="门店ID"  @keyup.enter.native="getmore()"></el-input>
                    <el-input style="width: 20vw;margin-left:20px " v-model="username" placeholder="匠人账号/姓名"  @keyup.enter.native="getmore()"></el-input>
                  
					<el-button @click="getmore()" type="primary"  style="margin-left: 20px;">搜索匠人</el-button>
					<el-button @click="download()" type="primary"  style="margin-left: 20px;">导出匠人列表</el-button>
				</div>

				<div style="height: auto;flex:1;" >
				<el-table
					height="70vh"
				    :data="list"
					border
				    style="width: 100%"
                    @selection-change="handleSelectionChange" 
					>
					<el-table-column
      					type="selection"
      					width="55">
    				</el-table-column>
				    <el-table-column
				      prop="userId"
				      label="用户编号"
				      width="200">
				    </el-table-column>
                    <el-table-column
				      prop="username"
				      label="匠人账号"
				      width="200">
				    </el-table-column>
                    <el-table-column
				      prop="name"
				      label="匠人姓名"
                      width="200"
				      >
				    </el-table-column>
					<el-table-column
						prop="avatar"
						label="匠人照片"
						width="250"
					>
						<template slot-scope="scope">
						    <el-image
							fit="cover"
						        style="min-width: 150px;width:150px;; min-height: 150px;height: 150px;"
						        :src=" scope.row.avatar"
						    ></el-image>
						</template>
					</el-table-column>
                    <el-table-column
				    
				      label="订单总额"
                      width="200"
				      >
					  <template slot-scope="scope" style="margin-left: auto;margin-right: auto;">
							{{  scope.row.money?scope.row.money:0}}
					    </template>
				    </el-table-column>
                    <el-table-column
				      prop="shopName"
				      label="所属门店"
                      width="200"
				      >
				    </el-table-column>
                    <el-table-column
				      prop="jifen"
				      label="积分"
                      width="200"
				      >
				    </el-table-column>

					<el-table-column
					      fixed="right"
					      label="操作"
						  width="200"
					      >

					      <template slot-scope="scope" style="margin-left: auto;margin-right: auto;">
							
					        <el-button v-if="scope.row.status==1" @click="handleClick(scope.row)" type="text" size="small">编辑</el-button>
							<el-button v-if="scope.row.status==1" @click="deleteuser(scope.row)" type="text" size="small">删除</el-button>
                            <el-button v-if="scope.row.status==0" @click="shenhe1(scope.row,1)" type="text" size="small">审核成功</el-button>
                            <el-button v-if="scope.row.status==0" @click="shenhe1(scope.row,0)" type="text" size="small">审核失败</el-button>
					      </template>
					</el-table-column>
				  </el-table>
				  <div style="font-size: 14px;height:30px;flex:1;width: 100%;display: flex;flex-direction: row;align-items: center;justify-content: center;margin-top: 10px;">
                        <el-pagination
                        @current-change="handleCurrentChange"
                        :current-page="page"
                        
                   
                        layout="total, prev, pager, next, jumper"
						:total="total"
                        :page-count="pagenum"
						:page-size="size">
                        </el-pagination>
				  </div>
				</div>
			</div>
		</div>


		
		<el-dialog
		:visible.sync="show"
		width="50%"
		top="2vh"
		>
		<div style="width:95%;height:75vh;padding:2.5%;overflow-y: auto;">
			<el-form ref="editform" :model="editform" label-width="120px">
			    <el-form-item label="匠人姓名">
			      <el-input v-model="editform.name"></el-input>
			    </el-form-item>
				<el-form-item label="匠人账号">
			      <el-input v-model="editform.username"></el-input>
			    </el-form-item>
				<el-form-item label="匠人密码">
			      <el-input v-model="editform.password" placeholder="不修改密码留空"></el-input>
			    </el-form-item>
				<el-form-item label="积分">
			      <el-input v-model="editform.jifen" type="number"></el-input>
			    </el-form-item>
				<el-form-item label="所属门店ID">
			      <el-input v-model="editform.shopid" type="number"></el-input>
			    </el-form-item>
				<el-form-item label="匠人图片">
					<el-upload
					 
					  action="https://jrapi.mqkitchen.com/total/uploadImage"
					  :show-file-list="false"
					  :on-success="handleAvatarSuccess2"
					  style="border-radius: 5px; border:0.5px dashed #909399;height: 150px;width: 150px;"
					  >
					  <el-image style="height: 150px;width: 150px;border-radius: 5px;" fit="cover" v-if="editform.avatar!=null"
					:src="editform.avatar" class="avatar"
					  @click="editform.avatar=null"
					  ></el-image>
					  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
					</el-upload>
				</el-form-item>
				 <el-form-item>
				    <el-button type="primary" @click="updateuser()">保存</el-button>
				    <el-button @click="show=false">取消</el-button>
				  </el-form-item>
			</el-form>
		</div>
		</el-dialog>
		<el-dialog
		:visible.sync="show1"
		width="50%"
		top="2vh"
		>
		<div style="width:95%;height:65vh;padding:2.5%;overflow-y: auto;">
			<el-form ref="form" :model="form" label-width="120px">
			    <el-form-item label="文章标题">
			      <el-input v-model="form.title"></el-input>
			    </el-form-item>
				 <el-form-item>
				    <el-button type="primary" @click="updatetheme()">保存</el-button>
				    <el-button @click="show=false">取消</el-button>
				  </el-form-item>
			</el-form>
		</div>
		</el-dialog>
		
	</div>
</template>

<script>
import md5 from 'js-md5';
import {post,get} from "../axios/request.js"
export default {
  name:"order",
  data() {
    return {
        shenhe:1,
		show:false,
		show1:false,
		name:null,
		shopid:null,
		username:null,
		total:0,
		page:1,
		size:20,
        list:[],
		pagenum:0,
        shenhe:1,
        form:{

        },
        newform:{

        },
		editform:{
			name:null,
			username:null,
			shopid:null,
			jifen:null,
			avatar:null,
			password:null
		},
        keyword:null,
		password:null,
        num:0,
        unpassnum:0,
        multipleSelection:[]
    }
  },
  mounted() {
	this.getmore();
  },
  methods: {
    handleCurrentChange(e){
        this.page=e;
        this.sub=0;
        this.getmore();
    },
    handleSelectionChange(val) {
        this.multipleSelection = val;
    },
	handleAvatarSuccess2(e){
		this.editform.avatar=e.location
	},
	download(){
		axios({
			method: 'post',
			url: 'https://jrapi.mqkitchen.com/total/exportReport',
			// url: 'http://127.0.0.1:7892/total/exportReport',
			// url: 'https://106.14.23.168:7892/total/exportReport',
			headers: {
				Authorization:localStorage.getItem("token")
			},
			data:{ status:0 },
			responseType: 'blob'
		}).then(res => {
			const link = document.createElement('a')
			const blob = new Blob([res.data], { type: 'application/vnd.ms-excel' })
			link.style.display = 'none'
			link.href = URL.createObjectURL(blob)
			link.setAttribute('download', `匠人列表.xlsx`)
			document.body.appendChild(link)
			link.click()
			document.body.removeChild(link)
		})
	},
	updateuser(){
		if(this.editform.password!=null&&this.editform.password.length>0){
			if(this.editform.password.length<6){
				this.$message.error("密码长度不能小于6位");
				return;
			}
		}
		if(this.editform.name==null||this.editform.name==''||this.editform.name==0){
			this.$message.error("请设置匠人姓名");
			return;
		}
		if(this.editform.username==null||this.editform.username==''||this.editform.username==0){
			this.$message.error("请设置匠人账号");
			return;
		}
		if(this.editform.jifen===null){
			this.$message.error("请设置匠人积分");
			return;
		}
		if(this.editform.shopid==null||this.editform.shopid==''||this.editform.shopid==0){
			this.$message.error("请设置匠人所属门店ID");
			return;
		}
		if(this.editform.avatar==null||this.editform.avatar==''||this.editform.avatar==0){
			this.$message.error("请设置匠人图片");
			return;
		}
		var form = JSON.parse(JSON.stringify(this.editform));
		if(form.password!=null){
			//修改密码
			post('/user/updatePwd',{newPwd:form.password,userId:form.userId,username:form.username}).then(res=>{
				console.log(res);
			})
			form.password=null;
		}
		//修改匠人信息
		post('/user/updateCraftsman',form).then(res=>{
			if(res.data.code==200){
				this.$message.success(res.data.msg)
				this.getmore();
				this.show=false;
			}else{
				this.$message.error(res.data.msg)
			}
		})
		console.log(form);
	},
	deleteuser(e) {
        this.$confirm('是否删除匠人 '+e.name+'?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          post('/user/remove',e).then(res=>{
			if(res.data.code==200){
				this.$message.success(res.data.msg)
				this.getmore();
				
			}else{
				this.$message.error(res.data.msg);
			}
		  })
        }).catch(() => {
        
        });
      },
    shenhe1(e,shenhe){
		e.status=shenhe;
        if(shenhe==2){
            this.$prompt('请输入拒绝信息', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            
            }).then(({ value }) => {
				e.statusContent=value;
				post("/user/updateStatus",{status:e.status,statusContent:e.statusContent,userId:e.userId}).then((res)=>{
				if(res.data.code==200){
           		 	this.$message.success(res.data.msg);
					this.getmore();
         	   }else{
					this.$message.error(res.data.msg);
				}
            }).catch(() => {
            this.$message({
                type: 'info',
                message: '取消输入'
            });       
            });
            return;
        })}
        else{
			e.statusContent='审核成功'

			post("/user/updateStatus?id=",{status:e.status,statusContent:e.statusContent,userId:e.userId}).then((res)=>{
				if(res.data.code==200){
           		 	this.$message.success(res.data.msg);
					this.getmore();
         	   }else{
					this.$message.error(res.data.msg);
				}
			})}
    },
	getmore(){
		if(this.keyword=='')this.keyword=null;
		if(this.username=='')this.username=null;
		if(this.shopid=='')this.shopid=null;
		if(this.name=='')this.name=null;
		post("/user/listByPage",
		{pageNo:this.page,pageSize:this.size,shopid:this.shopid,keyword:this.username,status:this.shenhe}
		).then((res)=>{
			if(res.data.code==200){
                this.list=res.data.page.list
				this.pagenum=res.data.page.pages;
				this.total=res.data.page.total            }
		})
	},
	last(){
		this.page--;
        this.sub=0;
		this.getmore();
	},
	next(){
		this.page++;
        this.sub=0;
		this.getmore();
	},
	handleClick(e){
		this.editform=JSON.parse(JSON.stringify(e));
		this.editform.password=null;
		this.show=true;
	},
    newtheme(){

    },
    updatetheme(){

    }
  }
}
</script>
<style scoped>
::v-deep .isdisabled  {
	background-color: white;
}
 .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 150px;
    height: 150px;
    line-height: 150px;
    text-align: center;
  }
  .avatar {
    width: 150px;
    height: 150px;
    display: block;
  }
</style>