<template>
	<div style="width: 100%;height: 100%;">
		<div style="width:98%;margin-left: 1%;margin-top:1.5%;height:95%;background-color: white;box-shadow:#e0e0e0 5px 5px 10px;">
			<div style="width:95%;height:96%;padding:2%;display: flex;flex-direction: column;">
			
                <div style="width:100%;display: flex;flex-direction: row;align-items: center;height:5vh;margin-bottom: 3vh;"
				>	
                   <el-button @click="show1=true" type="primary"  style="margin-left: 0px;">添加积分礼品</el-button>
				</div>
				<div style="height: auto;flex:1;" >
				<el-table
					height="70vh"
				    :data="list"
					border
				    style="width: 100%"
                    @selection-change="handleSelectionChange" 
					>
					<el-table-column
      					type="selection"
      					width="55">
    				</el-table-column>
				    <el-table-column
						prop="image"
						label="礼品图片"
						width="280"
					>
						<template slot-scope="scope">
						    <el-image
							fit="image"
						          style="width: 200px; height: 100px;"
						          :src=" scope.row.image"
						    ></el-image>
						</template>
					</el-table-column>
                    <el-table-column
				      prop="name"
				      label="礼品名称"
				      width="200">
				    </el-table-column>
                    <el-table-column
				      prop="score"
				      label="兑换积分"
                      width="100"
				      >
				    </el-table-column>
                    <el-table-column
				      prop="inventory"
				      label="礼品库存"
                      width="100"
				      >
				    </el-table-column>
                    <el-table-column
				      prop="notice"
				      label="兑换须知"
					
				      >
				    </el-table-column>
					<el-table-column
					      fixed="right"
					      label="操作"
						  width="100"
					      >
					      <template slot-scope="scope" style="margin-left: auto;margin-right: auto;">
					        <el-button  @click="handleClick(scope.row)" type="text" size="small">编辑</el-button>
							<el-button  @click="deletetheme(scope.row)" type="text" size="small">删除</el-button>
					      </template>
					</el-table-column>
				  </el-table>
				  <div style="font-size: 14px;height:30px;flex:1;width: 100%;display: flex;flex-direction: row;align-items: center;justify-content: center;margin-top: 10px;">
                        <el-pagination
                        @current-change="handleCurrentChange"
                        :current-page="page"
                        
                   
                        layout="total, prev, pager, next, jumper"
						:total="total"
                        :page-count="pagenum"
						:page-size="size">
                        </el-pagination>
				  </div>
				</div>
			</div>
		</div>


		
		<el-dialog
		:visible.sync="show1"
		width="50%"
		top="2vh"
		>
		<div style="width:95%;height:85vh;padding:2.5%;overflow-y: auto;">
			<el-form ref="newform" :model="newform" label-width="120px">
			    <el-form-item label="礼品名称">
			      <el-input v-model="newform.name"></el-input>
			    </el-form-item>
				<el-form-item label="兑换积分">
			      <el-input v-model="newform.score"></el-input>
			    </el-form-item>
				<el-form-item label="礼品库存">
			      <el-input v-model="newform.inventory"></el-input>
			    </el-form-item>
				<el-form-item label="兑换须知">
			      <el-input v-model="newform.notice"></el-input>
			    </el-form-item>
				<el-form-item label="礼品图片">
					<el-upload
					  class="avatar-uploader"
					  action="https://jrapi.mqkitchen.com/total/uploadImage"
					  :show-file-list="false"
					  :on-success="handleAvatarSuccess1"
					  style="border-radius: 5px; border:0.5px dashed #909399;height: 200px;width: 300px;"
					  >
					  <el-image style="height: 200px;width: 300px;border-radius: 5px;" fit="cover" v-if="newform.image!=null" :src="newform.image" class="avatar"
					  @click="newform.image=null"
					  ></el-image>
					  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
					</el-upload>
				</el-form-item>
				 <el-form-item>
				    <el-button type="primary" @click="newtheme()">保存</el-button>
				    <el-button @click="show1=false">取消</el-button>
				  </el-form-item>
			</el-form>
		</div>
		</el-dialog>
		<el-dialog
		:visible.sync="show"
		width="50%"
		top="2vh"
		>
		<div style="width:95%;height:85vh;padding:2.5%;overflow-y: auto;">
			<el-form ref="form" :model="form" label-width="120px">
			    <el-form-item label="礼品名称">
			      <el-input v-model="form.name"></el-input>
			    </el-form-item>
				<el-form-item label="兑换积分">
			      <el-input v-model="form.score"></el-input>
			    </el-form-item>
				<el-form-item label="礼品库存">
			      <el-input v-model="form.inventory"></el-input>
			    </el-form-item>
				<el-form-item label="兑换须知">
			      <el-input v-model="form.notice"></el-input>
			    </el-form-item>
				<el-form-item label="礼品图片">
					<el-upload
					  class="avatar-uploader"
					  action="https://jrapi.mqkitchen.com/total/uploadImage"
					  :show-file-list="false"
					  :on-success="handleAvatarSuccess2"
					  style="border-radius: 5px; border:0.5px dashed #909399;height: 200px;width: 300px;"
					  >
					  <el-image style="height: 200px;width: 300px;border-radius: 5px;" fit="cover" v-if="form.image!=null" :src="form.image" class="avatar"
					  @click="form.image=null"
					  ></el-image>
					  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
					</el-upload>
				</el-form-item>
				 <el-form-item>
				    <el-button type="primary" @click="updatetheme()">保存</el-button>
				    <el-button @click="show=false">取消</el-button>
				  </el-form-item>
			</el-form>
		</div>
		</el-dialog>
		
	</div>
</template>

<script>

import {post,get} from "../axios/request.js"
export default {
  name:"order",
  data() {
    return {
        shenhe:1,
		show:false,
		show1:false,
		page:1,
		pagenum:0,
		total:0,
		size:20,
        list:[],
        shenhe:1,
        form:{
			inventory:"",
			name:"",
			notice:"",
			image:null,
        },
        newform:{
			inventory:"",
			name:"",
			notice:"",
			image:null,
        },
        keyword:null,
        num:0,
        unpassnum:0,
        multipleSelection:[]
    }
  },
  mounted() {
	this.getmore();
  },
  methods: {
    handleCurrentChange(e){
        this.page=e;
        this.sub=0;
        this.getmore();
    },
    handleSelectionChange(val) {
        this.multipleSelection = val;
    },
	getmore(){

		post("/jiFen/listByPage",{pageNo:this.page,pageSize:this.size,keyword:this.keyword}).then((res)=>{
			if(res.data.code==200){
                this.list=res.data.page.list
				this.pagenum=res.data.page.pages;
				this.total=res.data.page.total            }
		})
	},
	last(){
		this.page--;
        this.sub=0;
		this.getmore();
	},
	next(){
		this.page++;
        this.sub=0;
		this.getmore();
	},
	handleClick(e){
		this.form=JSON.parse(JSON.stringify(e));
		this.show=true;
	},
	handleAvatarSuccess1(e){
		this.newform.image=e.location
	},
	handleAvatarSuccess2(e){
		this.form.image=e.location
	},
	newtheme(){
		post("/jiFen/insert",this.newform).then((res)=>{
			if(res.data.code==200){
				this.show1=false;
                this.$message.success(res.data.msg);
				this.newform={
					inventory:"",
					name:"",
					notice:"",
					image:"",
				}
				this.page=0;
			
				this.getmore();
            }else{
				this.$message.error(res.data.msg);
			}
		})
    },
    updatetheme(){
		post("/jiFen/update",this.form).then((res)=>{
			if(res.data.code==200){
				this.show=false;
                this.$message.success(res.data.msg);
			
				this.getmore();
            }else{
				this.$message.error(res.data.msg);
			}
		})
    },
	deletetheme(e){
		this.$confirm('是否删除此礼品?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
			console.log(e);
			post("/jiFen/delete?id="+e.id).then((res)=>{
			if(res.data.code==200){
                this.$message.success(res.data.msg);
				this.sub1=0;
				this.getmore();
            }else{
				this.$message.error(res.data.msg);
			}
		})
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });          
        });
	}
  }
}
</script>
<style scoped>
::v-deep .isdisabled  {
	background-color: white;
}
 .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 300px;
    height: 200px;
    line-height: 200px;
    text-align: center;
  }
  .avatar {
    width: 300px;
    height: 200px;
    display: block;
  }
</style>