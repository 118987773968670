<template>
  <router-view></router-view>
</template>
<script>

export default {
  data() {
    return {
     
    };
  }
};
</script>

<style>
body{
  margin:0;
  user-select: none;
}
</style>
