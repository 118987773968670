<template>
	<div style="width: 100%;height: 100%;">
		<div style="width:98%;margin-left: 1%;margin-top:1.5%;height:95%;background-color: white;box-shadow:#e0e0e0 5px 5px 10px;">
			<div style="width:95%;height:96%;padding:2%;display: flex;flex-direction: column;">
			

				<div style="height: auto;flex:1;" >
				<el-table
					height="70vh"
				    :data="list"
					border
				    style="width: 100%"	
					>
                    <el-table-column
                    type="index"
                    label="排名"
                    width="100">
                    </el-table-column>
				    <el-table-column
				      prop="userId"
				      label="匠人ID"
				      width="200">
				    </el-table-column>
                    <el-table-column
				      prop="name"
				      label="匠人姓名"
				     >
				    </el-table-column>
                    <el-table-column
				      prop="ordernum"
				      label="订单总数"
				     >
				    </el-table-column>
                    <el-table-column
				      prop="jifen"
				      label="总积分"
				     >
				    </el-table-column>
                    <el-table-column
				      prop="shopName"
				      label="所属门店"
				     >
				    </el-table-column>
				  </el-table>
                  <!--
				  <div style="font-size: 14px;height:30px;flex:1;width: 100%;display: flex;flex-direction: row;align-items: center;justify-content: center;margin-top: 10px;">
						<el-pagination
                        @current-change="handleCurrentChange"
                        :current-page="page"
                      
                 
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="400">
                        </el-pagination>
				  </div>
                -->
				</div>
			</div>
		</div>


		
		<el-dialog
		:visible.sync="show"
		width="50%"
		top="2vh"
		>
		<div style="width:95%;height:85vh;padding:2.5%;overflow-y: auto;">
			<el-form ref="newform" :model="newform" label-width="120px">
			    <el-form-item label="文章标题">
			      <el-input v-model="form.title"></el-input>
			    </el-form-item>
				 <el-form-item>
				    <el-button type="primary" @click="newtheme()">提交</el-button>
				    <el-button @click="show=false">取消</el-button>
				  </el-form-item>
			</el-form>
		</div>
		</el-dialog>
		<el-dialog
		:visible.sync="show1"
		width="50%"
		top="2vh"
		>
		<div style="width:95%;height:85vh;padding:2.5%;overflow-y: auto;">
			<el-form ref="form" :model="form" label-width="120px">
			    <el-form-item label="文章标题">
			      <el-input v-model="form.title"></el-input>
			    </el-form-item>
				 <el-form-item>
				    <el-button type="primary" @click="updatetheme()">保存</el-button>
				    <el-button @click="show=false">取消</el-button>
				  </el-form-item>
			</el-form>
		</div>
		</el-dialog>
		
	</div>
</template>

<script>

import {post,get} from "../axios/request.js"
export default {
  name:"order",
  data() {
    return {
		show:false,
		show1:false,
		page:1,
		size:20,
        list:[],
        form:{

        },
        newform:{

        },
        keyword:null,
        num:0,
        unpassnum:0,
		multipleSelection:[]
    }
  },
  mounted() {
	this.getmore();
  },
  methods: {
	handleCurrentChange(e){
        this.page=e;
        this.sub=0;
        this.getmore();
    },
	handleSelectionChange(val) {
        this.multipleSelection = val;
    },
    shenhe(e,shenhe){
        if(shenhe==0){
            this.$prompt('请输入拒绝信息', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
           
            }).then(({ value }) => {
            
            }).catch(() => {
            this.$message({
                type: 'info',
                message: '取消输入'
            });       
            });
            return;
        }
        else{
            return;
        }
    },
	getmore(){
		post("/user/selectAllList",{}).then((res)=>{
			if(res.data.code==200){
                this.list=res.data.list
            }
		})
	},
	last(){
		this.page--;
        this.sub=0;
		this.getmore();
	},
	next(){
		this.page++;
        this.sub=0;
		this.getmore();
	},
	handleClick(e){
		e=JSON.parse(JSON.stringify(e));
	},
    newtheme(){

    },
    updatetheme(){

    }
  }
}
</script>
<style scoped>
::v-deep .isdisabled  {
	background-color: white;
}
 .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 300px;
    height: 200px;
    line-height: 200px;
    text-align: center;
  }
  .avatar {
    width: 300px;
    height: 200px;
    display: block;
  }
</style>