<template>
	<div style="width: 100%;height: 100%;">
		<div style="width:98%;margin-left: 1%;margin-top:1.5%;height:95%;background-color: white;box-shadow:#e0e0e0 5px 5px 10px;">
			<div style="width:95%;height:96%;padding:2%;display: flex;flex-direction: column;">
			
				<div style="width:100%;display: flex;flex-direction: row;align-items: center;height:5vh;margin-bottom: 3vh;"
				
				>	
					<el-button @click="show1=true" type="primary" style="margin-left: 0px;">添加轮播图</el-button>
				</div>

				<div style="height: auto;flex:1;" >
				<el-table
					height="70vh"
				    :data="list"
					border
				    style="width: 100%"
					@selection-change="handleSelectionChange" 
					>
                    <el-table-column
						prop="cover"
						label="图片"
					>
						<template slot-scope="scope">
						    <el-image
							fit="cover"
						        style="width: 400px; height: 200px;"
						        :src=" scope.row.cover"
						    ></el-image>
						</template>
					</el-table-column>
					 <el-table-column
					      fixed="right"
					      label="操作"
						  width="200"
					      >
					      <template slot-scope="scope" style="margin-left: auto;margin-right: auto;">
					        <el-button @click="handleClick(scope.row)" type="text" size="small">编辑</el-button>
							<el-button @click="deletetheme(scope.row)" type="text" size="small">删除</el-button>
					      </template>
					    </el-table-column>
				  </el-table>
				  <div style="font-size: 14px;height:30px;flex:1;width: 100%;display: flex;flex-direction: row;align-items: center;justify-content: center;margin-top: 10px;">
					<el-pagination
                        @current-change="handleCurrentChange"
                        :current-page="page"
                        layout="total, prev, pager, next, jumper"
						:total="total"
                        :page-count="pagenum"
						:page-size="size">
                        </el-pagination>
				  </div>
				</div>
			</div>
		</div>


		
		<el-dialog
		:visible.sync="show"
		width="50%"
		top="2vh"
		>
		<div style="width:95%;height:70vh;padding:2.5%;overflow-y: auto;">
			<el-form ref="form" :model="form" label-width="120px">
				<el-form-item label="轮播图图片">
					<el-upload
					  class="avatar-uploader"
					  action="https://jrapi.mqkitchen.com/total/uploadImage"
					  :show-file-list="false"
					  :on-success="handleAvatarSuccess1"
					  style="border-radius: 5px; border:0.5px dashed #909399;height: 200px;width: 300px;"
					  >
					  <el-image style="height: 200px;width: 300px;border-radius: 5px;" fit="cover" v-if="form.cover!=null" :src="form.cover" class="avatar"
					  @click="form.cover=null"
					  ></el-image>
					  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
					</el-upload>
				</el-form-item>
				 <el-form-item>
				    <el-button type="primary" @click="updatetheme()">保存</el-button>
				    <el-button @click="show=false">取消</el-button>
				  </el-form-item>
			</el-form>
		</div>
		</el-dialog>
		<el-dialog
		:visible.sync="show1"
		width="50%"
		top="2vh"
		>
		<div style="width:95%;height:70vh;padding:2.5%;overflow-y: auto;">
			<el-form ref="newform" :model="newform" label-width="120px">
				<el-form-item label="轮播图图片">
					<el-upload
					  class="avatar-uploader"
					  action="https://jrapi.mqkitchen.com/total/uploadImage"
					  :show-file-list="false"
					  :on-success="handleAvatarSuccess2"
					  style="border-radius: 5px; border:0.5px dashed #909399;height: 200px;width: 300px;"
					  >
					  <el-image style="height: 200px;width: 300px;border-radius: 5px;" fit="cover" v-if="newform.cover!=null" :src="newform.cover" class="avatar"
					  @click="newform.cover=null"
					  ></el-image>
					  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
					</el-upload>
				</el-form-item>
				 <el-form-item>
				    <el-button type="primary" @click="newtheme()">发布</el-button>
				    <el-button @click="show1=false">取消</el-button>
				  </el-form-item>
			</el-form>
		</div>
		</el-dialog>
		
	</div>
</template>

<script>

import {post,get} from "../axios/request.js"
import {quillEditor, Quill} from 'vue-quill-editor'
import {container, ImageExtend, QuillWatch} from 'quill-image-extend-module'
Quill.register('modules/ImageExtend', ImageExtend)
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';

export default {
  name:"theme",
  components: {quillEditor},
  data() {
    return {
		show:false,
		show1:false,
		page:1,
		pagenum:0,
		total:0,
		size:20,
        list:[],
		editorOption: {  
          modules: {
			placeholder: "请输入文章正文",
            ImageExtend: {
              loading: true,
              name: 'file',
              action: 'https://jrapi.mqkitchen.com/total/uploadImage',
              response: (res) => {
                return res.location
              }
            },
            toolbar: {
              container: container,
              handlers: {
                'image': function () {
                  QuillWatch.emit(this.quill.id)
                }
              }
            }
			}
        },
		editorOption1: {  
          modules: {
			placeholder: "请输入文章正文",
            ImageExtend: {
              loading: true,
              name: 'file',
			  action: 'https://jrapi.mqkitchen.com/total/uploadImage',
              response: (res) => {
                return res.location
              }
            },
            toolbar: {
              container: container,
              handlers: {
                'image': function () {
                  QuillWatch.emit(this.quill.id)
                }
              }
            }
			}
        },
        form:{
			title:'',
			describes:'',
			cover:null,
			content:'',
			status:1
        },
        newform:{
			title:'',
			describes:'',
			cover:null,
			content:'',
			status:1
        },
        keyword:null,
		multipleSelection:[]
    }
  },
  mounted() {
	this.getmore();
  },
  methods: {
	handleAvatarSuccess1(e){
		this.form.cover = e.location;

		this.$forceUpdate();
	},
	handleAvatarSuccess2(e){
		this.newform.cover = e.location;
		this.$forceUpdate();
	},
	handleCurrentChange(e){
        this.page=e;
        this.sub=0;
        this.getmore();
    },
	handleSelectionChange(val) {
        this.multipleSelection = val;
    },
	getmore(){
		if(this.sub1==1)return;this.sub1=1;
		if(this.keyword=='')this.keyword=null;
		post("/article/listByPage",{pageNo:this.page,pageSize:this.size}).then((res)=>{
			if(res.data.code==200){
                this.list=res.data.page.list
				this.pagenum=res.data.page.pages;
				this.total=res.data.page.total				
            }
		})
	},
	last(){
		this.page--;
        this.sub=0;
		this.getmore();
	},
	next(){
		this.page++;
        this.sub=0;
		this.getmore();
	},
	handleClick(e){
		e=JSON.parse(JSON.stringify(e));
		this.form=e;
		this.show=true;
	},
    newtheme(){
		post("/article/insert",this.newform).then((res)=>{
			if(res.data.code==200){
				this.show1=false;
                this.$message.success(res.data.msg);
				this.newform={
					title:'',
					describes:'',
					cover:null,
					content:'',
					status:1
				}
				this.page=0;
				this.sub1=0;
				this.getmore();
            }else{
				this.$message.error(res.data.msg);
			}
		})
    },
    updatetheme(){
		post("/article/update",this.form).then((res)=>{
			if(res.data.code==200){
				this.show=false;
                this.$message.success(res.data.msg);
				this.sub1=0;
				this.getmore();
            }else{
				this.$message.error(res.data.msg);
			}
		})
    },
	deletetheme(e){
		this.$confirm('是否删除此文章?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
			console.log(e);
			post("/article/delete?id="+e.id).then((res)=>{
			if(res.data.code==200){
                this.$message.success(res.data.msg);
				this.sub1=0;
				this.getmore();
            }else{
				this.$message.error(res.data.msg);
			}
		})
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });          
        });
	}
  }
}
</script>
<style scoped>
.ql-font-Arial {
  font-family: "Arial";
}
::v-deep .isdisabled  {
	background-color: white;
}
 .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 300px;
    height: 200px;
    line-height: 200px;
    text-align: center;
  }
  .avatar {
    width: 300px;
    height: 200px;
    display: block;
  }
</style>