<template>
	<div style="width: 100%;height: 100%;">
		<div style="width:98%;margin-left: 1%;margin-top:1.5%;height:95%;background-color: white;box-shadow:#e0e0e0 5px 5px 10px;">
			<div style="width:95%;height:96%;padding:2%;display: flex;flex-direction: column;">
			
				<div style="width:100%;display: flex;flex-direction: row;align-items: center;height:5vh;margin-bottom: 3vh;"
				
				>	
                    <el-tag type="Dark"  v-if="shenhe==1" effect="dark">{{ '全部门店' }}</el-tag>
                    <el-tag type="Dark" @click="shenhe=1,getmore()"  v-if="shenhe!=1" effect="plain">{{ '全部门店' }}</el-tag>
                    <el-tag type="Dark"  v-if="shenhe==0" style="margin-left:20px" effect="dark">{{ '待审核' }}</el-tag>
                    <el-tag type="Dark" @click="shenhe=0,getmore()" v-if="shenhe!=0" style="margin-left:20px" effect="plain">{{ '待审核' }}</el-tag>
                    <el-tag type="Dark"  v-if="shenhe==2" style="margin-left:20px" effect="dark">{{ '审核失败' }}</el-tag>
                    <el-tag type="Dark" @click="shenhe=2,getmore()" v-if="shenhe!=2" style="margin-left:20px" effect="plain">{{ '审核失败' }}</el-tag>
                    <el-input style="width: 20vw;margin-left:20px " v-model="shopid" placeholder="门店编号"  @keyup.enter.native="shenhe=1,getmore()"></el-input>
                    <el-input style="width: 20vw;margin-left:20px " v-model="shopname" placeholder="门店名称"  @keyup.enter.native="shenhe=1,getmore()"></el-input>
					<el-button @click="shenhe=1,getmore()" type="primary"  style="margin-left: 20px;">搜索门店</el-button>
					<el-button @click="download" type="primary"  style="margin-left: 20px;">导出门店列表</el-button>
				</div>

				<div style="height: auto;flex:1;" >
				<el-table
					height="70vh"
				    :data="list"
					border
				    style="width: 100%"
                    @selection-change="handleSelectionChange" 
					>
					<el-table-column
      					type="selection"
      					width="55">
    				</el-table-column>
				    <el-table-column
				      prop="id"
				      label="门店编号"
				      width="200">
				    </el-table-column>
                    <el-table-column
				      prop="shopName"
				      label="门店名称"
				      width="200">
				    </el-table-column>
                    <el-table-column
				      prop="address"
				      label="门店地址"
                      width="230"
				      >
				    </el-table-column>
                    <el-table-column
				      prop="name"
				      label="负责人姓名"
                      width="150"
				      >
				    </el-table-column>
					<el-table-column
				      prop="username"
				      label="负责人账号"
                      width="150"
				      >
				    </el-table-column>
                    <el-table-column
				      prop="phone"
				      label="门店联系方式"
                      width="200"
				      >
				    </el-table-column>
					<el-table-column
				     
				      label="订单总量"
                      width="200"
				      >
					  <template slot-scope="scope" style="margin-left: auto;margin-right: auto;">
							{{ scope.row.ordernum?scope.row.ordernum:0 }}
					    </template>
				    </el-table-column>
					<el-table-column
					 
				      label="订单总金额"
                      width="200"
				      >
					  <template slot-scope="scope" style="margin-left: auto;margin-right: auto;">
							{{  scope.row.orderTotalMoney?scope.row.orderTotalMoney:0}}
					    </template>
				    </el-table-column>
                    <el-table-column
				      prop="createTime"
				      label="门店上线时间"
                      width="200"
				      >
				    </el-table-column>
					<el-table-column
						prop="photo"
						label="门店照片"
						width="250"
					>
						<template slot-scope="scope">
						    <el-image
							fit="cover"
						        style="min-width: 150px;width:150px;; min-height: 150px;height: 150px;"
						        :src=" scope.row.photo"
						    ></el-image>
						</template>
					</el-table-column>
                    <el-table-column
				     
				      label="审核状态"
                      width="200"
				      >
					  <template slot-scope="scope" style="margin-left: auto;margin-right: auto;">
							<p v-if="scope.row.shenhe==1" style="color:#5ac725">{{ '审核成功' }}</p>
							<p v-if="scope.row.shenhe==2" style="color:#f56c6c">{{ '审核失败' }}</p>
							<p v-if="scope.row.shenhe==0" style="color:#3c9cff">{{ '待审核' }}</p>
					    </template>
				    </el-table-column>
                    <el-table-column
				      prop="shenheContent"
				      label="审核备注"
                      width="200"
				      >
				    </el-table-column>
					<el-table-column
					      fixed="right"
					      label="操作"
						  width="200"
					      >
					      <template slot-scope="scope" style="margin-left: auto;margin-right: auto;">
							<el-button v-if="scope.row.shenhe==1" @click="bianji(scope.row)" type="text" size="small">编辑</el-button>
							<el-button v-if="scope.row.shenhe==1||scope.row.shenhe==2" @click="deletetheme(scope.row)" type="text" size="small">删除</el-button>
                            <el-button v-if="scope.row.shenhe==0||scope.row.shenhe==2" @click="shenhe1(scope.row,1)" type="text" size="small">审核成功</el-button>
                            <el-button v-if="scope.row.shenhe==0" @click="shenhe1(scope.row,2)" type="text" size="small">审核失败</el-button>
							
					      </template>
					</el-table-column>
				  </el-table>
				  <div style="font-size: 14px;height:30px;flex:1;width: 100%;display: flex;flex-direction: row;align-items: center;justify-content: center;margin-top: 10px;">
                        <el-pagination
                        @current-change="handleCurrentChange"
                        :current-page="page"
                     
                       
                        layout="total, prev, pager, next, jumper"
						:total="total"
                        :page-count="pagenum"
						:page-size="size">
                        </el-pagination>
				  </div>
				</div>
			</div>
		</div>


		
		<el-dialog
		:visible.sync="show"
		width="50%"
		top="2vh"
		>
		<div style="width:95%;height:60vh;padding:2.5%;overflow-y: auto;">
			<el-form ref="form" :model="form" label-width="120px">
			    <el-form-item label="门店名称">
			      <el-input v-model="form.shopName"></el-input>
			    </el-form-item>
				<el-form-item label="门店地址">
			      <el-input v-model="form.address"></el-input>
			    </el-form-item>
				<el-form-item label="门店联系方式">
			      <el-input v-model="form.phone"></el-input>
			    </el-form-item>
				<el-form-item label="负责人姓名">
			      <el-input v-model="form.name"></el-input>
			    </el-form-item>
				<el-form-item label="负责人联系方式">
			      <el-input v-model="form.username"></el-input>
			    </el-form-item>
				<el-form-item label="门店图片">
					<el-upload
					 
					  action="https://jrapi.mqkitchen.com/total/uploadImage"
					  :show-file-list="false"
					  :on-success="handleAvatarSuccess2"
					  style="border-radius: 5px; border:0.5px dashed #909399;height: 150px;width: 150px;"
					  >
					  <el-image style="height: 150px;width: 150px;border-radius: 5px;" fit="cover" v-if="form.photo!=null"
					:src="form.photo" class="avatar"
					  @click="form.photo=null"
					  ></el-image>
					  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
					</el-upload>
				</el-form-item>
				 <el-form-item>
					<div style="display: flex;flex-direction: row;align-items: center;">
						<div style="color:red">*</div><div style="margin-left:5px;margin-top:-10px"> 保存后需再次审核门店信息</div>
					</div>
				    <el-button type="primary" @click="save()">保存</el-button>
				    <el-button @click="show=false">取消</el-button>
				  </el-form-item>
			</el-form>
		</div>
		</el-dialog>
		
	</div>
</template>

<script>

import {post,get} from "../axios/request.js"
export default {
  name:"order",
  data() {
    return {
        shenhe:1,
        num:0,
        unpassnum:0,
		show:false,
		show1:false,
		shopname:null,
		page:1,
		total:0,
		size:20,
        list:[],
		pagenum:0,
        shenhe:1,
        form:{

        },
        newform:{

        },
        keyword:null,
        num:0,
		address:null,
		shopid:null,
        unpassnum:0,
        multipleSelection:[]
    }
  },
  mounted() {
	this.getmore();
  },
  methods: {
	
	bianji(e){
		this.form=JSON.parse(JSON.stringify(e));
		this.show=true;
	},
	deletetheme(e){
		this.$confirm('是否删除门店 '+e.shopName+'?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          post('/shop/delete?id='+e.id,{}).then(res=>{
			if(res.data.code==200){
				this.$message.success(res.data.msg)
				this.getmore();
				
			}else{
				this.$message.error(res.data.msg);
			}
		  })
        }).catch(() => {
        
        });
	},
    handleCurrentChange(e){
        this.page=e;
        this.sub=0;
        this.getmore();
    },
    handleSelectionChange(val) {
        this.multipleSelection = val;
    },
	handleAvatarSuccess2(e){
		this.form.photo=e.location
	},
    shenhe1(e,shenhe){
        if(shenhe==2){
            this.$prompt('请输入拒绝信息', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
           
            }).then(({ value }) => {
				post("/shop/updateShenHe",{id:e.id,shenhe:shenhe,shenheContent:value,uid:e.uid}).then((res)=>{
				if(res.data.code==200){
           		 	this.$message.success(res.data.msg);
					this.getmore();
         	   }else{
					this.$message.error(res.data.msg);
				}
            }).catch(() => {
            this.$message({
                type: 'info',
                message: '取消输入'
            });       
            });
            return;
        })}
        else{
			post("/shop/updateShenHe",{id:e.id,shenhe:shenhe,shenheContent:'审核成功',uid:e.uid}).then((res)=>{
				if(res.data.code==200){
           		 	this.$message.success(res.data.msg);
					this.getmore();
         	   }else{
					this.$message.error(res.data.msg);
				}
			})}
    },
	save(){
		this.form.shenhe=null;
		this.form.shenheContent=null;
		this.form.mobile=this.form.username;
		this.form.username=null;
		this.form.userId=this.form.uid;
		post("/shop/update",this.form).then((res)=>{
				if(res.data.code==200){
           		 	this.$message.success(res.data.msg);
					this.getmore();
         	   }else{
					this.$message.error(res.data.msg);
				}
		})
	},
	download(){
		axios({
			method: 'post',
			url: 'https://jrapi.mqkitchen.com/total/exportReport',
			headers: {
				Authorization:localStorage.getItem("token")
			},
			data:{ status:1 },
			responseType: 'blob'
		}).then(res => {
			const link = document.createElement('a')
			const blob = new Blob([res.data], { type: 'application/vnd.ms-excel' })
			link.style.display = 'none'
			link.href = URL.createObjectURL(blob)
			link.setAttribute('download', `门店列表.xlsx`)
			document.body.appendChild(link)
			link.click()
			document.body.removeChild(link)
		})
	},
	getmore(){
		if(this.shopid=='')this.shopid=null;
		if(this.shopname=='')this.shopname=null;
		post("/shop/listByPage",{pageNo:this.page,pageSize:this.size,shopName:this.shopname,id:parseInt(this.shopid),shenhe:this.shenhe}).then((res)=>{
			if(res.data.code==200){
                this.list=res.data.page.list
				this.pagenum=res.data.page.pages;
				this.total=res.data.page.total            }
		})
	},
	last(){
		this.page--;
        this.sub=0;
		this.getmore();
	},
	next(){
		this.page++;
        this.sub=0;
		this.getmore();
	},
	handleClick(e){
		e=JSON.parse(JSON.stringify(e));
	},
    newtheme(){

    },
    updatetheme(){

    }
  }
}
</script>
<style scoped>
::v-deep .isdisabled  {
	background-color: white;
}
 .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 150px;
    height: 150px;
    line-height: 150px;
    text-align: center;
  }
  .avatar {
    width: 150px;
    height: 150px;
    display: block;
  }
</style>