<template>
	<div style="width: 100%;height: 100%;">
		<div style="width:98%;margin-left: 1%;margin-top:1.5%;height:95%;background-color: white;box-shadow:#e0e0e0 5px 5px 10px;">
			<div style="width:95%;height:96%;padding:2%;display: flex;flex-direction: column;">
        <div style="width:100%;display: flex;flex-direction: row;align-items: center;height:5vh;margin-bottom: 3vh;"
				
				>	<el-date-picker
                    style="margin-left:0px"
                    v-model="value2"
                    type="datetimerange"
                    :picker-options="pickerOptions"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    @change="changetime"
                    align="right">
                    </el-date-picker>
				</div>


				<div style="height: auto;flex:1;" >
				<el-table
					height="70vh"
				    :data="list"
					border
				    style="width: 100%"
                    @selection-change="handleSelectionChange" 
					>
                    <el-table-column
                    type="index"
                    label="排名"
                    width="100">
                    </el-table-column>
                    <el-table-column
				      prop="name"
				      label="分类名称"
				     >
				    </el-table-column>
                    <el-table-column
				      prop="totalNumber"
				      label="销售数量"
				     >
				    </el-table-column>
                    <el-table-column
				      prop="numbili"
				      label="数量比例"
				     >
				    </el-table-column>
                    <el-table-column
				      prop="totalPrice"
				      label="销售金额"
				     >
				    </el-table-column>
                    <el-table-column
				      prop="moneybili"
				      label="金额比例"
				     >
				    </el-table-column>
				  </el-table>
				
				</div>
			</div>
		</div>

		
		
	</div>
</template>

<script>

import {post,get} from "../axios/request.js"
export default {
  name:"order",
  data() {
    return {
        value2:"",
        status:0,
		show:false,
		show1:false,
    starttime:'1970-01-01 00:00:00',
    endtime:null,
		page:1,
    total:0,
		size:20,
        list:[],
        shenhe:1,
        form:{

        },
        newform:{

        },
        keyword:null,
        num:0,
        unpassnum:0,
        multipleSelection:[],
        pickerOptions: {
          shortcuts: [{
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }]
        },
    }
  },
  mounted() {
    this.endtime=this.parseTime(new Date().getTime());
  	this.getmore();
  },
  methods: {
    changetime(e){
    this.endtime=this.parseTime(new Date(e[1]).getTime())
    this.starttime=this.parseTime(new Date(e[0]).getTime())
    this.getmore();
  },
    handleCurrentChange(e){
        this.page=e;
        this.sub=0;
        this.getmore();
    },
    handleSelectionChange(val) {
        this.multipleSelection = val;
    },
    shenhe1(e,shenhe){
        if(shenhe==0){
            this.$prompt('请输入拒绝信息', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
           
            }).then(({ value }) => {
            
            }).catch(() => {
            this.$message({
                type: 'info',
                message: '取消输入'
            });       
            });
            return;
        }
        else{
            return;
        }
    },
	getmore(){
	
		if(this.keyword=='')this.keyword=null;
		post("/total/statisticTotal",{pageNo:this.page,pageSize:this.size,status:2,startTime:this.starttime,endTime:this.endtime}).then((res)=>{
			if(res.data.code==200){
          this.list=res.data.list
          var money=0;
          var num=0;
          for(var i=0;i<this.list.length;i++){
              money+=this.list[i].totalPrice;
          }
          for(var i=0;i<this.list.length;i++){
            this.list[i].moneybili=(this.list[i].totalPrice/money*100).toFixed(2)+'%';
          }
          for(var i=0;i<this.list.length;i++){
              num+=this.list[i].totalNumber;
          }
          for(var i=0;i<this.list.length;i++){
            this.list[i].numbili=(this.list[i].totalNumber/num*100).toFixed(2)+'%';
          }
      }
		})
	},
	last(){
		this.page--;
        this.sub=0;
		this.getmore();
	},
	next(){
		this.page++;
        this.sub=0;
		this.getmore();
	},
	handleClick(e){
		e=JSON.parse(JSON.stringify(e));
	},
    newtheme(){

    },
    updatetheme(){

    },
    parseTime(time, cFormat) {
  if (arguments.length === 0) {
    return null
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if ((typeof time === 'string') && (/^[0-9]+$/.test(time))) {
      time = parseInt(time)
    }
    if ((typeof time === 'number') && (time.toString().length === 10)) {
      time = time * 1000
    }
    date = new Date(time)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  }
  const time_str = format.replace(/{([ymdhisa])+}/g, (result, key) => {
    const value = formatObj[key]
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') { return ['日', '一', '二', '三', '四', '五', '六'][value] }
    return value.toString().padStart(2, '0')
  })
  return time_str
}
  }
}
</script>
<style scoped>
::v-deep .isdisabled  {
	background-color: white;
}
 .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 300px;
    height: 200px;
    line-height: 200px;
    text-align: center;
  }
  .avatar {
    width: 300px;
    height: 200px;
    display: block;
  }
</style>