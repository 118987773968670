<template>
  <div style="height:100vh;width:100vw;background-image: url(https://jrimage.mqkitchen.com/bg.webp);background-size:100%;">
      <div style="background-color: rgba(0,0,0,0.4);width:100%;height:100%;display: flex;align-items: center;justify-content: center;">
			<div style="width:400px;height:400px;border-radius: 10px;background-color: white;display: flex;flex-direction: column;;align-items: center;;padding:20px">
				<p style="font-size: 20px;font-weight: 700">登录</p>
				<div style="width:80%;margin-bottom: 20px;margin-top: 10px;">
					<p style="margin-right: 10px;">账号</p><el-input v-model="username" placeholder="请输入账号"></el-input>
				</div>
				<div style="width:80%;margin-bottom: 30px;">
					<p style="margin-right: 10px;">密码</p><el-input type="password" v-model="pwd" placeholder="请输入密码"></el-input>	
				</div>
				 <el-button type="primary" style="width:80%" @click="login()">登录</el-button>
			</div>
      </div>
  </div>
 </template>
<script>
import {post} from "../axios/request.js"
export default {
  data() {
    return {
      username:"",
	  pwd:"",
    }
  },
  mounted() {
    if(localStorage.getItem("token")!=null){
		{
		var time = localStorage.getItem("time");
		var time1 = new Date().getTime();
		
		if(time<time1){
			localStorage.setItem("token",null);
			
		}
		else{
			this.$router.replace({path:"/home"})
		}
	}
		
	}
  },
  methods: {
	  login(){
		post("/loginByUsernameAndPassword",{username:this.username,pwd:this.pwd}).then((res)=>{
			if(res.data.code==200){
				var time = new Date().getTime()+3600*1000*2;
				localStorage.setItem("time",time);
				localStorage.setItem("userinfo",JSON.stringify(res.data.user));
				localStorage.setItem("token",res.data.token);
				this.$router.replace({path:"/"})
				console.log(localStorage.getItem("token"));
			}else{
				this.$message.error(res.data.msg);
			}
		})
	  }
  }
}
</script>

<style scoped>

</style>