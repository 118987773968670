<template>
	<div style="width: 100%;height: 100%;">
		<div style="width:98%;margin-left: 1%;margin-top:1.5%;height:95%;background-color: white;box-shadow:#e0e0e0 5px 5px 10px;">
			<div style="width:95%;height:96%;padding:2%;display: flex;flex-direction: column;">
			
				<div style="width:100%;display: flex;flex-direction: row;align-items: center;height:5vh;margin-bottom: 3vh;"
				
				>	
                    <el-tag type="Dark"  v-if="status==null" effect="dark">{{ '全部订单' }}</el-tag>
                    <el-tag type="Dark" @click="status=null,sub1=1,getmore()"  v-if="status!=null" effect="plain">{{ '全部订单' }}</el-tag>
                    <el-tag type="Dark"  v-if="status==0" style="margin-left:20px" effect="dark">{{ '待审核' }}</el-tag>
                    <el-tag type="Dark" @click="status=0,sub1=1,getmore()" v-if="status!=0" style="margin-left:20px" effect="plain">{{ '待审核' }}</el-tag>
                    <el-tag type="Dark"  v-if="status==1" style="margin-left:20px" effect="dark">{{ '审核成功' }}</el-tag>
                    <el-tag type="Dark" @click="status=1,sub1=1,getmore()" v-if="status!=1" style="margin-left:20px" effect="plain">{{ '审核成功' }}</el-tag>
                    <el-tag type="Dark"  v-if="status==2" style="margin-left:20px" effect="dark">{{ '审核失败' }}</el-tag>
                    <el-tag type="Dark" @click="status=2,sub1=1,getmore()" v-if="status!=2" style="margin-left:20px" effect="plain">{{ '审核失败' }}</el-tag>
					<el-input style="width: 20vw;margin-left:20px " v-model="keyword" placeholder="请输入搜索内容"  @keyup.enter.native="sub1=1,getmore()"></el-input>
					<el-button @click="sub1=1,getmore()" type="primary"  style="margin-left: 20px;">搜索订单</el-button>
					<el-button @click="download" type="primary"  style="margin-left: 20px;">导出订单列表</el-button>
				</div>

				<div style="height: auto;flex:1;" >
				<el-table
					height="70vh"
				    :data="list"
					border
				    style="width: 100%">
				    <el-table-column
				      prop="orderid"
				      label="订单编号"
				      width="100">
				    </el-table-column>
					<el-table-column
				
				      label="订单状态"
                      width="100"
				      >
					  <template slot-scope="scope" style="margin-left: auto;margin-right: auto;">
							<p v-if="scope.row.shenhe==1" style="color:#5ac725">{{ '审核成功' }}</p>
							<p v-if="scope.row.shenhe==2" style="color:#f56c6c">{{ '审核失败' }}</p>
							<p v-if="scope.row.shenhe==0" style="color:#3c9cff">{{ '待审核' }}</p>
					    </template>
				    </el-table-column>
                    <el-table-column
				      prop="createTime"
				      label="提交时间"
				      width="200">
				    </el-table-column>
                    <el-table-column
				      prop="shopid"
				      label="订单门店"
                      width="100"
				      >
				    </el-table-column>
                    <el-table-column
				      prop="ordermoney"
				      label="订单金额"
                      width="100"
				      >
				    </el-table-column>
					<el-table-column
				      prop="name"
				      label="匠人"
                      width="100"
				      >
				    </el-table-column>
					<el-table-column
				      prop="address"
				      label="地址"
                      width="200"
				      >
				    </el-table-column>
					<el-table-column
				      prop="mobile"
				      label="联系方式"
                      width="200"
				      >
				    </el-table-column>
					<el-table-column
				      prop="ordertime"
				      label="订单时间"
				      width="200">
				    </el-table-column>
                    <el-table-column
					      label="订单照片"
						  width="150"
					      >
					      <template slot-scope="scope" style="margin-left: auto;margin-right: auto;">
					        <el-button  type="text" size="small" @click="openimage(scope.row.image)">查看照片</el-button>
						
					      </template>
					</el-table-column>
                    <el-table-column
					      label="品类"
						  width="230"
					      >
					      <template slot-scope="scope" style="margin-left: auto;margin-right: auto;">
					        <div style="display: flex;flex-direction: column;width:100%;height: 100%;">
								<div style="display: flex;flex-direction: row;;" v-for="(item,index) in scope.row.ordersVoList">
									<p style="width:50%">{{ '名称:'+item.name }}</p>
									<p style="width:50%">{{ '数量:'+item.num }}</p>
								</div>
							</div>
						
					      </template>
					</el-table-column>
					<el-table-column
					      fixed="right"
					      label="操作"
						  width="100"
					      >
					      <template slot-scope="scope" style="margin-left: auto;margin-right: auto;">
							<el-button v-if="scope.row.shenhe==0" @click="openshenhe(scope.row)" type="text" size="small">审核</el-button>
							<el-button @click="deletetheme(scope.row)" type="text" size="small">删除</el-button>
					      </template>
					</el-table-column>
				  </el-table>
				  <div style="font-size: 14px;height:30px;flex:1;width: 100%;display: flex;flex-direction: row;align-items: center;justify-content: center;margin-top: 10px;">
						<el-pagination
                        @current-change="handleCurrentChange"
                        :current-page="page"
                       
                  
                        layout="total, prev, pager, next, jumper"
						:total="total"
                        :page-count="pagenum"
						:page-size="size"

						>
                        </el-pagination>
				  </div>
				</div>
			</div>
		</div>


		
		<el-dialog
		:visible.sync="show1"
		width="50%"
		top="2vh"
		>
		<div style="width:95%;height:40vh;padding:2.5%;overflow-y: auto;">
			<el-form ref="form" :model="shenheform" label-width="120px">
			    <el-form-item label="订单编号">
			      <el-input v-model="shenheform.orderid" disabled></el-input>
			    </el-form-item>
				<el-form-item label="审核状态">
					<el-radio v-model="shenheform.shenhe" :label="1">通过</el-radio>
  					<el-radio v-model="shenheform.shenhe" :label="2">拒绝</el-radio>
			    </el-form-item>
				<el-form-item label="审核备注">
			      <el-input v-model="shenheform.shenheContent"></el-input>
			    </el-form-item>
				 <el-form-item>
				    <el-button type="primary" @click="shenhe()">保存</el-button>
				    <el-button @click="show1=false">取消</el-button>
				  </el-form-item>
			</el-form>
		</div>
		</el-dialog>
		<el-image-viewer 
           v-if="show2" 
		   :on-close="close2"
           :url-list="[imgUrl]" /> 
	</div>
</template>

<script>

import {post,get} from "../axios/request.js"
import ElImageViewer from 'element-ui/packages/image/src/image-viewer' //注册组件 components: { ElImageViewer },
export default {
  name:"order",
  components: { ElImageViewer },
  data() {
    return {
		imgUrl:"",
        status:null,
		show:false,
		show1:false,
		show2:false,
		page:1,
		size:20,
		pagenum:0,
		total:0,
        list:[],
        form:{

        },
        shenheform:{

        },
        keyword:null
    }
  },
  mounted() {
	this.getmore();
  },
  methods: {
	openimage(e){
		console.log(e);
		this.imgUrl=e;
		this.show2=true;
		this.$forceUpdate();
	},
	close2(){
		this.show2=false
	},
	download(){
		axios({
			method: 'post',
			url: 'https://jrapi.mqkitchen.com/total/exportReport',
			headers: {
				Authorization:localStorage.getItem("token")
			},
			data:{ status:2 },
			responseType: 'blob'
		}).then(res => {
			const link = document.createElement('a')
			const blob = new Blob([res.data], { type: 'application/vnd.ms-excel' })
			link.style.display = 'none'
			link.href = URL.createObjectURL(blob)
			link.setAttribute('download', `订单列表.xlsx`)
			document.body.appendChild(link)
			link.click()
			document.body.removeChild(link)
		})
	},
	handleSelectionChange(val) {
        this.multipleSelection = val;
    },
	handleCurrentChange(e){
        this.page=e;
        this.sub=0;
        this.getmore();
    },
	getmore(){
		console.log(1);

		if(this.keyword=='')this.keyword=null;
		post("/orders/listByPage",{pageNo:this.page,pageSize:this.size,keyword:this.keyword,shenhe:this.status}).then((res)=>{
			if(res.data.code==200){
                this.list=res.data.page.list
				this.pagenum=res.data.page.pages;
				this.total=res.data.page.total
				
            }
		})
	},
	juge(e){
		console.log(e);
		if(e==0)return"待审核";
		if(e==1)return"审核通过";
		if(e==2)return"审核失败";
	},
	last(){
		this.page--;
        this.sub=0;
		this.getmore();
	},
	next(){
		this.page++;
        this.sub=0;
		this.getmore();
	},
	handleClick(e){
		e=JSON.parse(JSON.stringify(e));
	},
    newtheme(){

    },
    updatetheme(){

    },
	openshenhe(e){
		this.shenheform=e;
		this.show1=true;
	},
	shenhe(){
		post("/orders/updateShenHe",this.shenheform).then((res)=>{
			if(res.data.code==200){
				this.$message.success(res.data.msg);
				this.show1=false;
            }
			else{
				this.$message.error(res.data.msg);
			}
		})
	},
	deletetheme(e){
		this.$confirm('是否删除此订单?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
			console.log(e);
			post("/orders/delete?id="+e.orderid).then((res)=>{
			if(res.data.code==200){
                this.$message.success(res.data.msg);
				this.sub1=0;
				this.getmore();
            }else{
				this.$message.error(res.data.msg);
			}
		})
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });          
        });
	}
  }
}
</script>
<style scoped>
::v-deep .isdisabled  {
	background-color: white;
}
 .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 300px;
    height: 200px;
    line-height: 200px;
    text-align: center;
  }
  .avatar {
    width: 300px;
    height: 200px;
    display: block;
  }
</style>