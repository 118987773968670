<template>
  <div style="height:100%;width:100vw;display: flex;flex-direction: row">
	  <el-row style="height:100%;width:15vw;">
	    <el-col :span="12" style="height: 100%;overflow: auto;width:100%;" class="demo">
	         <div style="width:15vw;height:8vh;display: flex;align-items: center;justify-content: center;background-color: #191a23;color:white;position: fixed;z-index: 99;top:0;">
				管理后台
			</div>
			<div style="width:100%;height:8vh;display: flex;align-items: center;justify-content: center;background-color: #191a23;color:white">
				
			</div>
	        <el-menu
			  style="height: 92vh;width:100%"
	          default-active="0"
	          class="el-menu-vertical-demo"
	          background-color="#191a23"
	          text-color="#fff"
	          active-text-color="#3c9cff">  
			  <div  v-for="(item,index) in pagelist" :key="index">
	          <el-submenu  v-if="item.type==2" :index="index+''"
			
			  >
					<template slot="title">
					  <i :class="item.icon"></i>
					  <span>{{item.title}}</span>
					</template>
					<el-menu-item-group>
					  <el-menu-item   @click="page=item1.page" :index="(index+1)+'-'+(index1+1)" v-for="(item1,index1) in item.list">{{item1.title}}</el-menu-item>
					</el-menu-item-group>
					<!---->
	           </el-submenu>
				<el-menu-item :index="index+''"  v-if="item.type==1"   @click="page=item.page">
				    <i :class="item.icon"></i>
				    <span slot="title">{{item.title}}</span>
				</el-menu-item> 
				</div>
	        </el-menu>
	      </el-col>
	  </el-row>
	<div style="width:85vw;heigth:100vh;background-color: #fafafa;display: flex;flex-direction: column;">
		<div style="min-height:8vh;width:100%;height:8vh;background-color: white;border-bottom: 0.5px solid #dbdfe8;display: flex;align-items: center;flex-direction: row;">
			   <el-dropdown style="margin-left: auto;" @command="handleCommand">
			     <span class="el-dropdown-link">
			      <el-button slot="reference" style="padding:none;color:#606266;border:none;background-color: white;height:auto;">{{name}}</el-button>
			     </span>
			     <el-dropdown-menu slot="dropdown" >
			       <el-dropdown-item command="a">修改密码</el-dropdown-item>
			     </el-dropdown-menu>
			   </el-dropdown>
			<div style="width: 40px; height: 40px;border-radius: 50%;margin-left:0;overflow: hidden;">
			<el-image
			v-if="avatar"
			style="width: 40px; height: 40px;border-radius: 50%"
			:src="avatar"
			fit="cover"></el-image>
			</div>
			<P @click="loginout()" style="color:#606266;font-size: 14px;margin-right: 1.5%;margin-left: 1.5%;">退出登录</P>
		</div>
		<indexpage @changepage="changepage" v-if="page=='home1'" style="width:100%;height:auto;flex:1"></indexpage>
		<theme v-if="page=='theme'" style="width:100%;height:auto;flex:1"></theme>
		<order v-if="page=='order'" style="width:100%;height:auto;flex:1"></order>
		<jiangrenlist v-if="page=='jiangrenlist'" style="width:100%;height:auto;flex:1"></jiangrenlist>
		<jiangrentag v-if="page=='jiangrentag'" style="width:100%;height:auto;flex:1"></jiangrentag>
		<paihang v-if="page=='paihang'" style="width:100%;height:auto;flex:1"></paihang>
		<shoplist v-if="page=='shoplist'" style="width:100%;height:auto;flex:1"></shoplist>
		<tongji v-if="page=='tongji'" style="width:100%;height:auto;flex:1"></tongji>
		<itemtongji v-if="page=='itemtongji'" style="width:100%;height:auto;flex:1"></itemtongji>
		<pinleilist v-if="page=='pinleilist'" style="width:100%;height:auto;flex:1"></pinleilist>
		<jifen v-if="page=='jifen'" style="width:100%;height:auto;flex:1"></jifen>
		<duihuan v-if="page=='duihuan'" style="width:100%;height:auto;flex:1"></duihuan>
		<gongzhong v-if="page=='gongzhong'" style="width:100%;height:auto;flex:1"></gongzhong>
		<el-dialog
		  :visible.sync="show"
		  width="30%"
		  >
		  <div style="width:100%;height:50vh;">
			<el-form ref="form" :model="form" >
			    <el-form-item label="密码">
			      <el-input  type="password" v-model="form.password"></el-input>
			    </el-form-item>
				<el-form-item label="重复密码">
				  <el-input type="password"  v-model="form.repassword"></el-input>
				</el-form-item>
				 <el-form-item>
				    <el-button type="primary" @click="changepassword">修改</el-button>
				    <el-button @click="show=false">取消</el-button>
				  </el-form-item>
			</el-form>
		  </div>
		</el-dialog>
	</div>
  </div>
</template>
<script>
import { post } from "../axios/request.js"
import indexpage from "@/components/indexpage.vue"
import theme from "@/components/theme.vue"
import order from "@/components/order.vue"
import jiangrenlist from "@/components/jiangrenlist.vue"
import jiangrentag from "@/components/jiangrentag.vue"
import paihang from "@/components/paihang.vue"
import shoplist from "@/components/shoplist.vue"
import tongji from "@/components/tongji.vue"
import itemtongji from "@/components/itemtongji.vue"
import pinleilist from "@/components/pinleilist.vue"
import jifen from "@/components/jifen.vue"
import duihuan from "@/components/duihuan.vue"
import gongzhong from "@/components/gongzhong.vue"
export default {
components: { 
	indexpage,theme,order,jiangrenlist,jiangrentag,paihang,shoplist,tongji,itemtongji,pinleilist,jifen,duihuan,gongzhong
},
  data() {
    return {
      tableData: [],
	  show:false,
      search: '',
	  name:"管理员",
	  avatar:"",
	  nickname:"",
	  page:"home1",
	  visible:false,
	  form:{
		password:"",
		repassword:""
	  },
	  pagelist:[
		{
			type:1,
			title:"首页",
			page:"home1",
			icon:"el-icon-menu"
		},
		{
			type:1,
			title:"轮播图管理",
			page:"theme",
			icon:"el-icon-menu",
		},
		{
			type:1,
			title:"订单管理",
			icon:"el-icon-menu",
			page:"order"
		},
		{
			type:2,
			title:"匠人管理",
			icon:"el-icon-menu",
			list:[
				{
					title:"匠人列表",
					page:"jiangrenlist"
				},
				{
					title:"匠人标签",
					page:"jiangrentag"
				},
				{
					title:"工种管理",
					page:"gongzhong"
				},
				{
					title:"匠人排行",
					page:"paihang"
				}
			]
		},
		{
			type:1,
			title:"门店管理",
			icon:"el-icon-menu",
			page:"shoplist"
		},
		{
			type:2,
			title:"统计",
			icon:"el-icon-menu",
			list:[
				{
					title:"综合统计",
					page:"tongji"
				},
				{
					title:"商品统计",
					page:"itemtongji"
				}
			]
		},
		{
			type:2,
			title:"品类",
			icon:"el-icon-menu",
			list:[
				{
					title:"品类列表",
					page:"pinleilist"
				},
				{
					title:"积分礼品",
					page:"jifen"
				},
				{
					title:"积分兑换",
					page:"duihuan"
				}
			]
		},
	  ]
    }
  },
  mounted() {
    if(localStorage.getItem("token")==null){
      this.$router.push({path:"/login"});
    }else{
		var time = localStorage.getItem("time");
		var time1 = new Date().getTime();
		
		if(time<time1){
			localStorage.setItem("token",null);
			this.$router.push({path:"/login"});
		}
	}
  },
  methods: {
	changepage(e){
		this.page=e;
	},
	handleCommand(e){
		this.show=true;
	},
	changepassword(){
		if(this.form.password!=this.form.repassword){
			this.$message.error('两次输入的密码不一致')
			return;
		}
		if(this.form.password.length<6){
			this.$message.error('密码长度不能小于6位')
			return;
		}
		var token = (localStorage.getItem("token"));
		post("/user/changepassword",{password:this.form.password,token:token}).then((res)=>{
			if(res.data.code==1001){
				this.$message.success("修改成功")
				this.show=false;
				this.form={
					password:"",
					repassword:""
				}
				setTimeout(
				this.loginout()
				,1500)
				
			}else{
				this.$message.success("修改失败")
			}
		})
	},
	loginout(){
		localStorage.removeItem("token")
		localStorage.removeItem("userinfo");
		this.$router.replace({path:"/login"})
	},
    getBidding(){
      /*axios.get('/api/redis/bidding').then(res => {
        this.tableData = res.data;
      })*/
    },
  },
}
</script>
<style>
.demo {
  scrollbar-width: none; /* firefox */
  -ms-overflow-style: none; /* IE 10+ */
  overflow-x: hidden;
  overflow-y: auto;
}
body{
	height:100vh;
	padding:0;
	margin:0;
	overflow: hidden;
}
</style>
