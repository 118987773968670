
import axios from "axios";
//let baseURL = "http://127.0.0.1:7889";
let baseURL = "https://jrapi.mqkitchen.com";
//封装对应的get请求
export const get = (url, params) => {
    return new Promise((resolve, reject) => {
        axios.get(baseURL+url,
            {
                param:{
                    
                },
                headers:{
                    Authorization:localStorage.getItem("token")
                }
            },
           
        )
        .then(res => resolve(res))
        .catch(err => reject(err));
    });
}
//封装对应的post请求
export const post = (url, param) => {
    return new Promise((resolve, reject) => {
        axios.post(baseURL+url, param,{
            headers:{
                Authorization:localStorage.getItem("token")
            }
        })
        .then(res => resolve(res))
        .catch(err => reject(err));
    });
}

